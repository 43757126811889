.friendBox {
  /* border: solid 2px green; */
  background-color: dimgray;
  max-width: 300px;
  padding: 10px;
  margin: 10px;
}

.btnAddTx {
  float: right;
}
